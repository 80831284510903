// /* eslint-disable react-hooks/exhaustive-deps */
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import HomeIcon from '@material-ui/icons/Home';
import { Upload } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ChartSkeleton from '../../components/ChartSkeleton';
import PromotionUsageViewsChart from '../../components/Promotion/PromotionUsageViewsCharts';
import { getFileDimensions } from '../../helper/getFileDimensions';
import { getS3FileDimensions } from '../../helper/getS3FileDimensions';
import { renameS3File } from '../../helper/renameS3File';
import { uploadFileOnS3 } from '../../helper/s3Uploader';
import services from '../../services/index';

toast.configure();

const CampaignDetails = (props) => {
  const cam = useSelector(state => state.client.campaign);
  const { setPage } = props;
  const couponImage = require('../../assets/images/logo-placeholder.png');
  const [campaign, setCampaign] = useState(cam);
  const [loading, setLoading] = useState(false);
  const [usage, setUsage] = useState([]);
  const [views, setViews] = useState([]);
  const [saves, setSaves] = useState([]);
  const [couponForm, setCouponForm] = useState({});
  const [editCampaign, setEditCampaign] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);

  const [fileList, setFileList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiFiles, setApiFiles] = useState([]);
  const [newUploadedFile, setNewUploadedFile] = useState([]);
  const [oldEditedFile, setOldEditedFile] = useState([]);

  const notify = (type, text) => toast[type](text, {
    position: toast.POSITION.TOP_RIGHT,
  });

  const resetForm = () => {
    setCouponForm({ coupons_type: 'P' });
  }

  const handleDelete = () => {
    services.CampaignServices.deletePromotion(campaign._id)
      .then(() => {
        setPage('promotions');
      })
      .catch(err => console.log('Delete Promotion error: ', err));
  };

  const handleEdit = async () => {

    setLoading(true);

    let dimensions
    if (apiFiles?.length === 0) {
      dimensions = await getFileDimensions(newUploadedFile[0]);
    } else {
      dimensions = await getS3FileDimensions(apiFiles[0]);
    }

    let renamedUrl = [];
    let newUrl = [];
    let oldUrl = campaign?.campaign_images?.map((file) => { return ({ images_url: file.images_url }) });

    oldEditedFile && await renameS3File(oldEditedFile, dimensions, "product")
      .then((res) => {
        if (res?.data?.status) {
          renamedUrl.push(...res?.data?.data)
        } else {
          notify("error", "Something went to wrong.");
          setLoading(false);
          setErrorMessage("");
        }
      })
      .catch((error) => {
        notify("error", "Something went to wrong.");
        console.log("image upload error", error);
        setLoading(false);
        setErrorMessage("");
      })

    newUploadedFile && await uploadFileOnS3(newUploadedFile, dimensions, "product")
      .then((res) => {
        res.forEach((item) => {
          newUrl.push({ images_url: item.Location })
        })
      })
      .catch((error) => {
        notify("error", "Something went to wrong.");
        console.log("image upload error", error);
        setLoading(false);
        setErrorMessage("");
      });

    let files = [];

    if (oldEditedFile?.length === 0 && newUploadedFile?.length !== 0) {
      if (apiFiles?.length === 0) {
        files.push(...newUrl)
      } else {
        files.push(...oldUrl)
        files.push(...newUrl)
      }
    }
    if (oldEditedFile?.length !== 0 && newUploadedFile?.length !== 0) {
      files.push(...renamedUrl)
      files.push(...newUrl)
    }
    if (oldEditedFile?.length !== 0 && newUploadedFile?.length === 0) {
      files.push(...renamedUrl)
    }

    files?.length !== 0 && (editCampaign.campaign_images = files);

    await services.CampaignServices.updatePromotion(campaign._id, editCampaign).then(res => {
        if (res?.data?.err) {
          setLoading(false);
          notify('error', res?.data?.message);
          return false;
        }
        setNewUploadedFile([]);
        setOldEditedFile([]);
        notify('success', 'Promotion updated successfully.');
        setCampaign(res?.data?.data?.campaign);
        setShowEdit(false);
        setLoading(false);
      })
      .catch((err) => {
        notify('error', 'Something went to wrong.');
        console.log('Update Promotion error:', err)
        setLoading(false);
      });
  };

  const getCampaign = async () => {
    await services.CampaignServices.getPromotion(campaign._id)
      .then(res => {
        setApiFiles(res?.data?.data?.campaign?.campaign_images)
        setCampaign(res?.data?.data?.campaign);
        setUsage(res?.data?.data?.usage);
        setViews(res?.data?.data?.views);
        setCouponForm({ couponForm, campaign: res?.data?.data?.campaign._id, client: res?.data?.data?.campaign.client });
        setEditCampaign({
          ...editCampaign,
          campaign_start_date: moment(campaign.campaign_start_date).utc(),
          campaign_expiration_date: moment(campaign.campaign_expiration_date).utc()
        });
      })
      .catch(err => console.error('getCampaign', err));
  };

  useEffect(() => {
    getCampaign();
  }, [campaign.coupons?.length]);

  let newCouponStartDate;
  if (moment(campaign.campaign_start_date).unix() > moment().unix()) {
    newCouponStartDate = moment(campaign.campaign_start_date).subtract(1, 'days');
  } else {
    newCouponStartDate = moment().subtract(1, 'days');
  }

  const setRunPeriod = (startDate, endDate) => {
    var newEndDate = (endDate != null) ? moment(endDate) : moment(startDate);
    setEditCampaign({ ...editCampaign, campaign_start_date: moment(startDate), campaign_expiration_date: newEndDate });
  }

  const onCloseModal = () => {
    setShowEdit(false);
    setLoading(false);
    setNewUploadedFile([]);
  }

  let tempFile = [];
  const propsUploadFile = {
    onRemove: (file) => {
      setFileList((prevFileList) =>
        prevFileList.filter((item) => item !== file)
      );

      const filteredImages = newUploadedFile.filter(
        (item) => item !== file
      );
      setNewUploadedFile([...filteredImages])
    },
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");

      const maxSize = 50 * 1024 * 1024;
      if (file.size > maxSize) {
        setErrorMessage("File size exceeds the maximum allowed size (50MB).");
        return false;
      } else {
        setErrorMessage("");
      }

      // If it's an image or video, add it to the fileList
      if (isImage || isVideo) {
        setFileList((prevFileList) => [...prevFileList, file]);
        tempFile.push(file);
        setNewUploadedFile([
          ...newUploadedFile, ...tempFile
        ])
      } else {
        setErrorMessage(
          "Invalid file type. Only images and videos are allowed."
        );
        return false;
      }
      return false;
    },
    fileList,
    multiple: true,
    showUploadList: false,
  };

  const handlePreview = (file) => {
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");
    if (isImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
      };
      reader.readAsDataURL(file);
    } else if (isVideo) {
    }
  };

  const handleRemoveNewFile = (file) => {
    setFileList((prevFileList) => prevFileList.filter((item) => item !== file));
    const filteredImages = newUploadedFile.filter((item) => item !== file);
    setNewUploadedFile([...filteredImages]);
    setErrorMessage("");
  };

  const handleRemoveOldFile = (file) => {
    const filteredImages = apiFiles.filter((item) => item.id !== file.id);
    setApiFiles(filteredImages);
    setOldEditedFile(filteredImages);
  }

  useEffect(() => {
    if (newUploadedFile?.length + apiFiles?.length > 5) {
      setErrorMessage("You can only upload up to 5 files.");
    } else {
      setErrorMessage("");
    }
  }, [newUploadedFile, apiFiles]);

  return (
    <>
      <>
        <div className="row">
          <div className="col">
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="javascript:;" onClick={() => setPage('dashboard')}>
                <HomeIcon />
              </Link>
              <Link color="inherit" href="javascript:;" onClick={() => setPage('promotions')}>
                <Typography color="primary">Promotions</Typography>
              </Link>
              <Typography color="textPrimary">Promotion Details</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            {campaign ? (
              <Card>
                <CardHeader
                  title="Promotion Details"
                  subheader="Manage Promotion details and create offers"
                  action={
                    <>
                      <IconButton aria-label="edit Promotion" title="Edit Promotion" onClick={() => {
                        setShowEdit(true);
                        setEditCampaign({
                          campaign_start_date: moment(campaign.campaign_start_date).utc(),
                          campaign_expiration_date: moment(campaign.campaign_expiration_date).utc()
                        })
                        setApiFiles(campaign.campaign_images)
                      }}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete Promotion" title="Delete Promotion" onClick={() => setShowDelete(true)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  }
                />
                <CardContent>
                  <div className="row">
                    <div className="col-12 col-lg-3">
                      {campaign.campaign_image ?
                        (campaign.campaign_image?.includes("video") ? <video src={`${process.env.REACT_APP_AWS_BUCKET_URL}${campaign.campaign_image}`} style={{ width: "auto", height: "120px" }} controls></video> :
                          <img src={`${process.env.REACT_APP_AWS_BUCKET_URL}${campaign.campaign_image}`} alt="Coupon" style={{ width: "auto", height: "120px" }} />)
                        :
                        <img src={couponImage} alt="prev" style={{ width: "auto", height: "120px" }} />}
                      {/* <img src={campaign.campaign_image ? config.apiUrl + campaign.campaign_image : couponImage} /> */}
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 mt-lg-0 mt-3">
                      <h6><u>Promotion Name</u></h6>
                      <p>{campaign.campaign_name}</p>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 mt-lg-0 mt-md-3 mt-0">
                      <h6><u>Promotion Description</u></h6>
                      <p>{campaign.campaign_description}</p>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 mt-lg-0 mt-md-3 mt-0">
                      <h6><u>Run Time</u></h6>
                      <p>{moment(campaign.campaign_start_date).format("MMM Do YYYY")} - {moment(campaign.campaign_expiration_date).format("MMM Do YYYY")}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <ChartSkeleton />
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            {campaign ? (<Card>
              <CardHeader
                title={"Promotion Views"}
                subheader={'Daily Views & Usage for this promotion.'}
              />
              <CardContent>
                <PromotionUsageViewsChart data={{ campaign, usage, views, saves }} height='300px' />
              </CardContent>
            </Card>
            ) : (
              <ChartSkeleton />
            )}
          </div>
        </div>
      </>
      <Modal centered show={showEdit} onHide={() => onCloseModal()} size="lg" className='edit_promotion_modal'>
        <Modal.Header closeButton>
          <Modal.Title>EDIT A PROMOTION</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mt-3'>
            <div className='col-lg-6 col-12'>
              <Form.Group>
                <Form.Label>Promotion Name</Form.Label>
                <Form.Control
                  className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                  placeholder="Promotion Name"
                  type="text"
                  defaultValue={editCampaign.campaign_name || campaign.campaign_name}
                  onChange={(e) => setEditCampaign({ ...editCampaign, campaign_name: e.target.value })}
                />
              </Form.Group>
            </div>
            <div className='col-lg-6 col-12'>
              <Form.Group>
                <Form.Label>Promotion Run Period</Form.Label>
                <DateRangePicker
                  orientation={window.innerWidth >= 768 ? "horizontal" : 'vertical'}
                  startDateId="startDate"
                  endDateId="endDate"
                  startDate={editCampaign.campaign_start_date || campaign.campaign_start_date}
                  endDate={editCampaign.campaign_expiration_date || campaign.campaign_expiration_date}
                  //onDatesChange={({ startDate, endDate }) => setEditCampaign({ ...editCampaign, campaign_start_date: moment(startDate),  campaign_expiration_date: moment(endDate) })}
                  onDatesChange={({ startDate, endDate }) => setRunPeriod(startDate, endDate)}
                  focusedInput={focusedInput}
                  onFocusChange={(focused) => setFocusedInput(focused)}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group>
                <Form.Label>Promotion Description</Form.Label>
                <textarea
                  name="description"
                  onChange={(e) => setEditCampaign({ ...editCampaign, campaign_description: e.target.value })}
                  className="form-control"
                  rows="3"
                  defaultValue={editCampaign.campaign_description || campaign.campaign_description}></textarea>
              </Form.Group>
            </div>
          </div>
          <div className='row mt-3'>
            <div className="col-12">
              <div>
                <Form.Label>
                  Product Image / Video (50 MB max.)
                </Form.Label>
                <Upload
                  {...propsUploadFile}
                  onPreview={handlePreview}
                  accept="image/*,video/*"
                >
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Button
                      className="choose_file_button"
                      onClick={() => setErrorMessage("")}
                    >
                      Choose file
                    </Button>
                  </div>
                </Upload>
                {errorMessage && (
                  <div className="mt-1 text-danger">
                    {errorMessage}
                  </div>
                )}
              </div>
            </div>
            <div className='col-12 mt-3'>
              <Form.Label>Promotion Preview</Form.Label>
              {(apiFiles?.length === 0 && newUploadedFile?.length === 0) && <img src={couponImage} alt="Coupon" width="150px" height="100px" />}
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {
                  apiFiles.map((file) => {
                    const { images_url, fileType } = file;
                    const isImage = fileType === "image";
                    const isVideo = fileType === "video";

                    return (
                      <>
                        {isImage && (
                          <div className="position-relative d-inline-block">
                            <div className="upload_preview_cancel_icon"
                              onClick={() => handleRemoveOldFile(file)}
                            > X</div>
                            <img
                              src={images_url}
                              alt={file.name}
                              className="border rounded"
                              style={{ width: "auto", height: "100px" }}
                            />
                          </div>
                        )}
                        {(
                          isVideo &&
                          <div className="position-relative d-inline-block">
                            <div className="upload_preview_cancel_icon"
                              onClick={() => handleRemoveOldFile(file)}
                            >X</div>
                            <video
                              controls
                              src={images_url}
                              className="border rounded"
                              style={{ width: "auto", height: "100px" }}
                            />
                          </div>
                        )}
                      </>
                    )
                  })
                }
                {
                  newUploadedFile?.length !== 0 && newUploadedFile.map((file) => {
                    return (
                      <>
                        {file.type.startsWith("image/") ? (
                          <div className="position-relative">
                            <div
                              className="upload_preview_cancel_icon"
                              onClick={() => handleRemoveNewFile(file)}
                            >
                              X
                            </div>
                            <img
                              src={URL?.createObjectURL(file)}
                              alt={file.name}
                              className="border rounded"
                              style={{ width: "auto", height: "100px" }}
                            />
                          </div>
                        ) : (
                          <div className="position-relative">
                            <div
                              className="upload_preview_cancel_icon"
                              onClick={() => handleRemoveNewFile(file)}
                            >
                              X
                            </div>
                            <video
                              controls
                              src={URL?.createObjectURL(file)}
                              className="border rounded"
                              style={{ width: "auto", height: "100px" }}
                            />
                          </div>
                        )}
                      </>
                    )
                  })
                }
              </div>
              {/* <Row gutter={16}>
                {campaign?.campaign_images?.map(item => {
                  const { _id, images_url, fileType } = item;
                  const isImage = fileType === "image";
                  const isVideo = fileType === "video";
                  return (
                    <Col className="gutter-row" key={_id} style={{ marginBottom: '16px' }}>
                      {isImage && <img src={images_url} className='border rounded' alt={`Image ${_id}`} style={{ width: 'auto', height: '100px' }} />}
                      {isVideo && (
                        <video controls src={images_url} className='border rounded' style={{ width: 'auto', height: '100px' }}></video>
                      )}
                    </Col>
                  );
                })}
              </Row> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={loading || (apiFiles?.length === 0 && newUploadedFile?.length === 0) || errorMessage} onClick={() => handleEdit()}>
            {loading &&
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className='mr-2'
              />
            }
            Update Promotion
          </Button>
          <Button variant="outline-dark" onClick={() => onCloseModal()} style={{ marginLeft: '20px' }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal centered show={showDelete} onHide={() => setShowDelete(false)} size="md">
        <Modal.Header closeButton variant="danger">
          <Modal.Title>DELETE A PROMOTION</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete <b>"{campaign.campaign_name}"</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleDelete()}>
            Yes
          </Button>
          <Button variant="outline-dark" onClick={() => resetForm(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CampaignDetails;