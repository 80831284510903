import AWS from 'aws-sdk';

const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME;
const REGION = process.env.REACT_APP_AWS_REGION_NAME;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

export const uploadFileOnS3 = async (files, dimensions, name) => {
    const uploadPromises = [];

    for (const uploadedFile of files) {
        const params = {
            Bucket: S3_BUCKET,
            Key: uploadedFile.type.split("/")[0] + "s/" + name + "/" + Date.now() + "__" + dimensions.width + "-" + dimensions.height + "__" + uploadedFile.name,
            Body: uploadedFile,
            ACL: 'public-read',
        };

        const uploadPromise = myBucket.upload(params).promise();
        uploadPromises.push(uploadPromise);
    }

    try {
        const results = await Promise.all(uploadPromises);
        return results; // Return the uploaded file details
    } catch (error) {
        console.error("Error uploading files:", error);
        throw error; // Rethrow the error to be handled by the caller
    }
}